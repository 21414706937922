import React, { useContext, useState } from "react";
import { useLocation, useNavigate } from "react-router";

import { Breadcrumbs, Button, Card, CardBody, CardHeader } from "components";
import LevelForm from "./levelForm";
import { FormProvider, useForm } from "react-hook-form";
import { getDetailPrivilage, updateDetailPrivilage } from "services/leveManagement";
import { useMutation } from "@tanstack/react-query";
import { ToastContext } from "providers/toastProvider";

const LevelManagementAccess = () => {
  const navigate = useNavigate();

  const { state } = useLocation();
  const { showToastMessage } = useContext(ToastContext);

  const [detailPrivilage, setDetailPrivilage] = useState();
  const [moduleId, setModuleId] = useState(null);

  const mutation = useMutation({
    mutationFn: async (someProps) => {
      try {
        const update = await updateDetailPrivilage(someProps);
        return update;
      } catch (error) {
        console.error("Error:", error);
        throw error;
      }
    }
  });

  const methods = useForm({
    defaultValues: async () => {
      const res = await getDetailPrivilage(state.privilage);
      setModuleId(res.data.data?.privileges[0]?.idModule)
      setDetailPrivilage(res?.data.data);
      return {
        level: res.data.data?.name ?? '',
        privilege : res.data.data?.privileges ?? []
      }
    }
  });

  const links = [
    {
      path: "/level-management",
      label: "Management Level User",
    },
    {
      path: "/level-management/access",
      label: "Hak Access Level",
    },
  ];

  const onSubmit = async (data) => {
    try {
      mutation.mutate({
        idlevel: state.privilage,
        privileges: data.privilege
      });

      showToastMessage("Data Berhasil Diperbarui", "");

      navigate('/level-management')
    } catch (e) {
      console.log(e)

      showToastMessage("Data Gagal Diperbarui", e.message, "error");
    }
  }

  return (
        <FormProvider {...methods}>
        <div className="space-y-6">
          <Breadcrumbs items={links} />
        <Card>
          <CardHeader>
            <div>Tambah Data</div>
          </CardHeader>
          <CardBody>
            <LevelForm/>
          </CardBody>
        </Card>

        <div className="tabs flex-nowrap">
            {detailPrivilage?.privileges.length > 0 ? detailPrivilage?.privileges.map((data, index) => {
              return (
                <a className={`tab w-full tab-bordered ${data.idModule == moduleId ? 'bg-primary-600 text-white': ''} `} onClick={() => {setModuleId(data.idModule);}}>{data.moduleName}</a> 
              )
            }) : ''}
          </div>
          <table className="table w-full text-gray-600">
            <thead className="border-b">
              <tr>
                <th className="bg-primary-600 text-white">No</th>
                <th className="bg-primary-600 text-white">Menu</th>
                <th className="bg-primary-600 text-white text-center">List Data</th>
                <th className="bg-primary-600 text-white text-center">Tambah Data</th>
                <th className="bg-primary-600 text-white text-center">Edit Data</th>
                <th className="bg-primary-600 text-white text-center">Hapus Data</th>
              </tr>
            </thead>
            <tbody>
              {detailPrivilage?.privileges.length > 0 ? detailPrivilage?.privileges.map((data, index) => {
                return data.menuModules.length > 0 && data.idModule === moduleId? data.menuModules.map((dataModules, indexModules) => {
                    return (
                      <tr className="border-b">
                        <td>{indexModules + 1}</td>
                        <td>{dataModules.menuName}</td>
                        <td className="text-center">
                          <input
                            {... methods.register(`privilege.${index}.menuModules.${indexModules}.canView`)}
                            type={"checkbox"}
                            className="peer border-gray-300 checked:bg-primary-600 checked:hover:bg-primary-600 checked:active:bg-primary-600 checked:focus:bg-primary-600 focus:bg-primary-600 focus:outline-none focus:ring-1 focus:ring-primary-600"
                            name={`privilege.${index}.menuModules.${indexModules}.canView`}
                          />
                        </td>
                        <td className="text-center">
                          <input
                            {... methods.register(`privilege.${index}.menuModules.${indexModules}.canCreate`)}
                            type={"checkbox"}
                            className="peer border-gray-300 checked:bg-primary-600 checked:hover:bg-primary-600 checked:active:bg-primary-600 checked:focus:bg-primary-600 focus:bg-primary-600 focus:outline-none focus:ring-1 focus:ring-primary-600"
                            name={`privilege.${index}.menuModules.${indexModules}.canCreate`}
                          />
                        </td>
                        <td className="text-center">
                          <input
                            {... methods.register(`privilege.${index}.menuModules.${indexModules}.canUpdate`)}
                            type={"checkbox"}
                            className="peer border-gray-300 checked:bg-primary-600 checked:hover:bg-primary-600 checked:active:bg-primary-600 checked:focus:bg-primary-600 focus:bg-primary-600 focus:outline-none focus:ring-1 focus:ring-primary-600"
                            name={`privilege.${index}.menuModules.${indexModules}.canUpdate`}
                          />
                        </td>
                        <td className="text-center">
                          <input
                            {... methods.register(`privilege.${index}.menuModules.${indexModules}.canDelete`)}
                            type={"checkbox"}
                            className="peer border-gray-300 checked:bg-primary-600 checked:hover:bg-primary-600 checked:active:bg-primary-600 checked:focus:bg-primary-600 focus:bg-primary-600 focus:outline-none focus:ring-1 focus:ring-primary-600"
                            name={`privilege.${index}.menuModules.${indexModules}.canDelete`}
                          />
                        </td>
                      </tr>
                    )
                }): ''
              }) : ''}
            </tbody>
          </table>
      <div className="block md:flex items-center justify-between px-6 py-5 border border-gray-200 rounded-lg mt-4 bg-white mb-10">
        <Button
          type="button"
          className="text-gray-700 border-gray-300 bg-white hover:bg-gray-300 w-60"
          onClick={() => navigate(-1)}>Cancel</Button>
        <Button
          type="button"
          onClick={() => methods.handleSubmit(onSubmit)()}
          className="text-white border-primary-600 bg-primary-600 hover:bg-primary-700 hover:border-primary-700 w-60"
        >Submit
        </Button>
      </div>
          </div>
          </FormProvider>
  );
};

export default LevelManagementAccess;
