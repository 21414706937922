import React from "react";
import { Label } from "components";
import { InputForm } from "components/HookForm";
import { useFormContext } from "react-hook-form";

const GroupForm = ({ fieldsDisabled = [] }) => {
  const { register } = useFormContext();
  
  return (
    <div className="space-y-5">
      <div className="space-y-2 flex-1">
        <Label required>Group User Name</Label>
        <InputForm
          className="w-full"
          {...register("name", { required: true })}
          disabled={fieldsDisabled.includes("name")}
        />
      </div>
      <div className="space-y-2 flex-1">
        <Label>Description</Label>
        <InputForm
          type="textarea"
          className="w-full"
          name="description"
          disabled={fieldsDisabled.includes("description")}
        />
      </div>
    </div>
  );
};

export default GroupForm;
