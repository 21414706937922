import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { BsPersonPlusFill } from "react-icons/bs";
import { debounce } from "lodash";
import { useQuery } from "@tanstack/react-query";

import { Button } from "components";
import { BasicList } from "components/ListPage";
import { FormModal } from "components/Modal";
import { getListUserInternalWEmployeeInfo } from "services/userInternal";
import { ToastContext } from "providers/toastProvider";

const PopupSelectUserInternal = ({ disabled = false, onSubmit = (selectedUser) => null }) => {
  const { showToastMessage } = useContext(ToastContext);

  const [open, setOpen] = useState(false);

  const [userInternals, setUserInternals] = useState();
  const [selectedUsers, setSelectedUsers] = useState([]);

  const [params, setParams] = useState({});
  const [pagination, setPagination] = useState({
    page: 1,
    limit: 10,
    total: 7,
    previous_pages: 0,
    next_pages: 2,
  });

  const paginator = useMemo(() => {
    return {
      page: 1,
      limit: 5,
    };
  }, []);

  const { data } = useQuery({
    queryKey: ["list-user-internal", params],
    queryFn: async () => {
      const res = await getListUserInternalWEmployeeInfo(params);
      return res?.data?.data || [];
    },
    retry: 1,
    enabled: !disabled,
  });

  const columns = useMemo(() => {
    return [
      {
        id: "no",
        title: "No",
        dataIndex: "no",
        className: "overflow-hidden",
        render: (value, data, index) => {
          const no =
            parseInt(paginator?.page) * parseInt(paginator?.limit) -
            parseInt(paginator?.limit) +
            index +
            1;
          return (
            <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
              {no}
            </div>
          );
        },
      },
      {
        id: "actions",
        title: "Aksi",
        dataIndex: "code",
        width: 160,
        className: "overflow-hidden text-ellipsis",
        sortable: true,
        fixed: "right",
        render: (value, data) => (
          <div className="space-x-3 flex items-center w-full">
            <input
              className={`peer border-gray-300 checked:bg-primary-600 checked:hover:bg-primary-600 checked:active:bg-primary-600 checked:focus:bg-primary-600 focus:bg-primary-600 focus:outline-none focus:ring-1 focus:ring-primary-600`}
              type={"checkbox"}
              value={value}
              onChange={(e) => handleSelectUser(e, data)}
            />
          </div>
        ),
      },
      {
        id: "name",
        title: "Nama User",
        dataIndex: "name",
        width: 540,
        className: "overflow-hidden text-ellipsis",
        sortable: true,
        render: (value, data) => (
          <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
            {value}
            <br />
            <em>{data.nip}</em>
          </div>
        ),
      },
      {
        id: "directorat",
        title: "Directorat",
        dataIndex: "directorat",
        width: 540,
        className: "overflow-hidden text-ellipsis",
        sortable: true,
        render: (value, data) => (
          <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
            {value}
            <br />
            <em>{data.divisi}</em>
          </div>
        ),
      },
      {
        id: "jabatan",
        title: "Jabatan",
        dataIndex: "jabatan",
        width: 540,
        className: "overflow-hidden text-ellipsis align-top",
        sortable: true,
        render: (value) => (
          <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
            {value}
          </div>
        ),
      },
    ];
  }, []);

  const handleSelectUser = (e, data) => {
    setSelectedUsers((currentSelectedUsers) => {
      const isChecked = e.target.checked;

      if (isChecked) {
        return [
          ...currentSelectedUsers,
          {
            code: data.code,
            name: data.name,
            nip: data.nip,
            directorat: data.directorat,
            divisi: data.divisi,
            jabatan: data.jabatan,
            checked: true,
            type: "internal",
          },
        ];
      }

      return currentSelectedUsers.filter((user) => user.code !== data.code);
    });
  };

  const handleSubmit = () => {
    if (!selectedUsers.length) {
      showToastMessage("Batal submit", "Tidak ada data yang dipilih", "error");
      setOpen(false);
      return;
    }

    showToastMessage(
      "Berhasil tambah user",
      `${selectedUsers.length} user berhasil ditambahkan`
    );

    setSelectedUsers([]);
    onSubmit(selectedUsers);
    setOpen(false);
  };

  const onSearch = debounce(
    useCallback((event) => {
      setParams({
        ...{ generalSearch: event.target.value },
      });
    }, []),
    1000
  );

  const setNewLimit = (event) => {
    setParams({ ...{ pageSize: event } });
  };

  const setNewPage = (event) => {
    setParams({ ...{ pageNumber: event } });
  };

  useEffect(() => {
    setUserInternals(
      data?.data.map((entry) => {
        return {
          ...entry,
          code: entry.idUser,
        };
      })
    );

    setPagination({
      page: data?.currentPage,
      limit: data?.pageSize,
      total: data?.totalCount,
      previous_pages: data?.currentPage - 1,
      next_pages: data?.currentPage + 1,
    });
  }, [data]);

  return (
    <>
      <Button
        type="button"
        onClick={() => setOpen(true)}
        className="text-white border-primary-600 bg-primary-600 hover:bg-primary-700 hover:border-primary-700"
        disabled={disabled}
      >
        <BsPersonPlusFill className="mr-2" /> User Internal
      </Button>

      <FormModal
        title={"Tambah Data User"}
        open={open}
        onClose={() => setOpen(false)}
        onSubmit={handleSubmit}
      >
        <BasicList
          onSearch={onSearch}
          columns={columns}
          dataSource={userInternals}
          onChangePage={setNewPage}
          onChangeRowsPerPage={setNewLimit}
          pagination={pagination}
        />
      </FormModal>
    </>
  );
};

export default PopupSelectUserInternal;
